body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
    position: absolute;
    top: 0;
    right: 0;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #eceaea;
    background-image: url("https://ajax.googleapis.com/ajax/libs/dojo/1.10.4/dijit/themes/claro/images/loadingAnimation.gif");
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000000;
    opacity: 0.8;
    filter: alpha(opacity=40);
}

.tag-12 {
    margin: 0 3px;
    vertical-align: middle;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
}

.tag-13 {
    margin: 0 3px;
    vertical-align: middle;
    display: inline-block;
    font-size: 13px;
    cursor: pointer;
}

.tag-27 {
    margin: 0 3px;
    vertical-align: middle;
    display: inline-block;
    font-size: 27px;
    cursor: pointer;
}

.tag-32 {
    margin: 0 3px;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    font-size: 32px;
}
